@tailwind base;
@tailwind components;
@tailwind utilities;

.editor a {
    color: blue !important;
    text-decoration: underline !important;
}

.text-hover:hover,
.text-hover *:hover {
    color: rgb(92, 130, 168) !important;
}

.swiper-button-next,
.swiper-button-prev {
    color: black !important;
    text-shadow: 0px 0px px white;
    user-select: none;

    &:hover {
        transform: scale(1.1);
    }
    &:active {
        transform: scale(1);
    }
}

.border {
    border: 1px solid white;
}

.hover-image {
    overflow: hidden;
    transition: transform 200ms ease-in-out;
}

.hover-image:hover {
    transform: scale(1.02);
    color: blue !important;
}
.scrollbar-hidden::-webkit-scrollbar {
    display: none;
}
