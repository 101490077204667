.dropdown {
    overflow: hidden;
}

.dropdownContent {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 150px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdownContent a {
    color: black;
    padding-inline: 15px;
    padding-block: 5px;
    text-decoration: none;
    display: block;
    text-align: left;
    text-shadow: 0px 0px 1px black;
}

.rightGrid a {
    padding-right: 30px;
}

.dropdownContent a:hover {
    background-color: #ddd;
}

.dropdown:hover .dropdownContent {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 50px;
}