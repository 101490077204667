@import '@/styles/utils.scss';

.footerContainer {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 40px;
    row-gap: 20px;
    @include mq(L) {
        grid-template-columns: repeat(12, 1fr);
    }
}

.left {
    gap: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    a {
        border: 1px solid white;
        border-radius: 50%;
        padding: 2px;
        opacity: 0.7;
    }
    @include mq(L) {
        grid-column: 1/5;
    }
}

.right {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 10px;
    @include mq(L) {
        grid-column: 5/-1;
        grid-template-columns: repeat(5, 1fr);
    }
}
